import React from "react";
import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        padding: "0px",
        background:
          "linear-gradient(180deg,rgb(0,153,124.03) 0%,rgb(0,184.9,123.27) 100%,rgb(0,184.9,123.27) 100%)",
        font: {
          lg: "normal normal 300 20px/25px Poppins",
          md: "normal normal 300 20px/25px Poppins",
          sm: "normal normal 300 18px/25px Poppins",
          xs: "normal normal 300 16px/25px Poppins",
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          color: "white",
          fontSize: "24px",
          lineHeight: "1",
          paddingLeft: { xs: "5%", md: "10%" },
          paddingTop: "20px",
        }}
      >
        Amarya Business Consultancy
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-around", md: "space-between" },
          flexDirection: { xs: "column", md: "row" },
          flexWrap: "wrap",
          paddingLeft: { xs: "5%", md: "10%" },
          paddingBottom: "50px",
        }}
      >
        <Box
          sx={{
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "space-between",
            marginTop: "50px",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Poppins-Regular, Helvetica",
              fontWeight: "normal",
              lineHeight: "1",
              font: {
                lg: "normal normal 300 18px/25px Poppins",
                md: "normal normal 300 18px/25px Poppins",
                sm: "normal normal 300 18px/25px Poppins",
                xs: "normal normal 300 16px/25px Poppins",
              },
              color: "#FFFFFF",
              whiteSpace: "pre-wrap",
            }}
          >
            <span style={{ color: "white" }}>Connect </span>
            <span style={{ color: "white" }}>with us:</span>
          </Typography>
          <a
            href="https://www.facebook.com/profile.php?id=61554031243550"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/./Images/fb.svg`}
              sx={{
                margin: { xs: "15px 0px 0px 0px", md: "20px 10px 0px 0px" },
                // width: "100%",
                height: "auto",
              }}
            />
          </a>
          <a
            href="https://www.instagram.com/amaryabusinessconsultancy/?fbclid=IwAR3raV3vgSfCZ8EWr1kpjVreriaHyedSnoFM7uAXDASSyDJdWHRuZUtCGh4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/./Images/insta.svg`}
              sx={{
                // padding: "0px 0px 10px 0px",
                margin: { xs: "15px 0px 0px 20px", md: "20px 10px 0px 10px" },
                // width: "100%",
                height: "auto",
              }}
            />
          </a>
          <a
            href=" https://www.linkedin.com/company/amarya-business-consultancy-1/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/./Images/linkedin.svg`}
              sx={{
                // padding: "0px 0px 10px 0px",
                margin: { xs: "15px 0px 0px 20px", md: "20px 10px 0px 10px" },
                // width: "100%",
                height: "auto",
              }}
            />
          </a>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/./Images/twitter.svg`}
            sx={{
              // padding: "0px 0px 10px 0px",
              margin: { xs: "15px 0px 0px 20px", md: "20px 10px 0px 10px" },
              // width: "100%",
              height: "auto",
            }}
          />
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/./Images/whtspp.svg`}
            sx={{
              // padding: "10px 10px 10px 10px",
              margin: { xs: "15px 0px 0px 20px", md: "20px 20px 0px 10px" },
              // width: "100%",
              height: "auto",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            marginTop: { xs: "40px", md: "50px" },
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Poppins-Regular, Helvetica",
              fontWeight: "normal",
              fontSize: "20px",
              lineHeight: "1",
              font: {
                lg: "normal normal 300 18px/25px Poppins",
                md: "normal normal 300 18px/25px Poppins",
                sm: "normal normal 300 18px/25px Poppins",
                xs: "normal normal 300 16px/20px Poppins",
              },
              color: "#FFFFFF",
              whiteSpace: "pre-wrap",
            }}
          >
            Address
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Poppins-Regular, Helvetica",
              fontWeight: "normal",
              fontSize: "20px",
              lineHeight: "1",
              marginTop: { xs: "5px", md: "10px" },
              font: {
                lg: "normal normal 300 18px/25px Poppins",
                md: "normal normal 300 18px/25px Poppins",
                sm: "normal normal 300 18px/25px Poppins",
                xs: "normal normal 300 16px/25px Poppins",
              },
              color: "#FFFFFF",
              whiteSpace: "pre-wrap",
            }}
          >
            40, Kingsway
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Poppins-Regular, Helvetica",
              fontWeight: "normal",
              fontSize: "20px",
              lineHeight: "1",
              marginTop: { xs: "5px", md: "10px" },
              font: {
                lg: "normal normal 300 18px/25px Poppins",
                md: "normal normal 300 18px/25px Poppins",
                sm: "normal normal 300 18px/25px Poppins",
                xs: "normal normal 300 16px/25px Poppins",
              },
              color: "#FFFFFF",
              whiteSpace: "pre-wrap",
            }}
          >
            Sadar,Jabalpur
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Poppins-Regular, Helvetica",
              fontWeight: "normal",
              fontSize: "20px",
              lineHeight: "1",
              marginTop: { xs: "5px", md: "10px" },
              font: {
                lg: "normal normal 300 18px/25px Poppins",
                md: "normal normal 300 18px/25px Poppins",
                sm: "normal normal 300 18px/25px Poppins",
                xs: "normal normal 300 16px/25px Poppins",
              },
              color: "#FFFFFF",
              whiteSpace: "pre-wrap",
            }}
          >
            (M.P) 482001
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            marginTop: "50px",
            marginRight: "10%",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Poppins-Regular, Helvetica",
              fontWeight: "normal",
              fontSize: "20px",
              lineHeight: "1",
              font: {
                lg: "normal normal 300 18px/25px Poppins",
                md: "normal normal 300 18px/25px Poppins",
                sm: "normal normal 300 18px/25px Poppins",
                xs: "normal normal 300 16px/25px Poppins",
              },
              color: "#FFFFFF",
              whiteSpace: "pre-wrap",
            }}
          >
            Contact Information
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Poppins-Regular, Helvetica",
              fontWeight: "normal",
              fontSize: "20px",
              lineHeight: "1",
              font: {
                lg: "normal normal 300 18px/25px Poppins",
                md: "normal normal 300 18px/25px Poppins",
                sm: "normal normal 300 18px/25px Poppins",
                xs: "normal normal 300 16px/25px Poppins",
              },
              color: "#FFFFFF",
              whiteSpace: "pre-wrap",
            }}
          >
            Phone - +91 9650052355 (Monday to Friday, 9 am to 5 pm)
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontFamily: "Poppins-Regular, Helvetica",
              fontWeight: "normal",
              lineHeight: "1",
              font: {
                lg: "normal normal 300 18px/25px Poppins",
                md: "normal normal 300 18px/25px Poppins",
                sm: "normal normal 300 18px/25px Poppins",
                xs: "normal normal 300 16px/25px Poppins",
              },
              color: "#FFFFFF",
              whiteSpace: "pre-wrap",
            }}
          >
            Email -{" "}
            <span style={{ textDecoration: "underline" }}>
              contactus@amaryaconsultancy.com
            </span>
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="body1"
        sx={{
          font: {
            lg: "normal normal 300 16px/25px Poppins",
            md: "normal normal 300 18px/25px Poppins",
            sm: "normal normal 300 18px/25px Poppins",
            xs: "normal normal 300 16px/25px Poppins",
          },
          color: "#FFFFFF",
          whiteSpace: "pre-wrap",
          width: "100%",
          paddingLeft: { xs: "5%", md: "10%" },
          borderBottom: "2px solid white",
          paddingBottom: "10px",
        }}
      >
        If you have a question, feedback, or simply want to say hello, we're
        here to assist you. Reach out to us using any of the above methods:
      </Typography>
      <Typography
        variant="body1"
        sx={{
          font: {
            lg: "normal normal 300 16px/25px Poppins",
            md: "normal normal 300 18px/25px Poppins",
            sm: "normal normal 300 18px/25px Poppins",
            xs: "normal normal 300 16px/25px Poppins",
          },
          color: "#FFFFFF",
          whiteSpace: "pre-wrap",
          paddingLeft: "10%",
          padding: "10px 0px 10px 10%",
        }}
      >
        <span style={{ marginRight: "30px" }}>Terms & Conditions</span>
        <span> Privacy Policy</span>
      </Typography>
    </Box>
  );
};

export default Footer;
